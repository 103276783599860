<template>
    <div class="user-content" style="">
        <div class="user-info-title">我的土地
          <el-button type="primary" style="margin-bottom:20px; background: #0960bd;padding: 8px 14px; float: right"  @click="formDialogRef.openNewDialog()">编辑匹配规则</el-button>
        </div>
        <div class="user-info-list">

            <el-table :data="data.tableData" style="width: 100%">
                <el-table-column type="index" label="序号" width="160">
                </el-table-column>
                <el-table-column label="地块标题" width="330" p>

                  <template #default="scope">
                    <span style="cursor: pointer;" @click="toDetail(scope.row.landInfo)" >{{scope.row.landInfo.title}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="landPurpose" label="土地用途" width="330">
					<template #default="scope">

            {{ getLandPurposeName( scope.row.landInfo.landPurpose) }}
					</template>
				</el-table-column>
				<el-table-column prop="auditStatus" label="审核状态" width="140" show-overflow-tooltip>
					<template #default="scope">
						{{ scope.row.landInfo.auditStatus == 2 ? '审核通过' : scope.row.landInfo.auditStatus == 3 ? '审核不通过' : '待审核' }}
					</template>
				</el-table-column>
                <!-- <el-table-column prop="action" label="操作">
                </el-table-column> -->
            </el-table>
          <div class="user-info-list-page">
            <el-pagination background  style="float: right" layout="total,  prev, pager, next, jumper"  :page-count="data.totalPage" :current-page="data.currentPage" @current-change="pageChange" >
            </el-pagination>
          </div>

        </div>
      <form-dialog ref="formDialogRef" @refresh="pageChange(data.currentPage)" />
    </div>
</template>
<script setup>
import {reactive, onMounted, defineAsyncComponent, ref} from 'vue'
import CommonService from '@/service/CommonService';
import { useDict, findLabel } from "@/hooks/dict";
import {useRouter} from "vue-router/composables";

const router = useRouter();
const formDialog = defineAsyncComponent(() => import('./match_add.vue'));
const { land_purpose } = useDict('land_purpose')

const getLandPurposeName= (id)=> {
  let land_purposes = useDict('demand_purpose');
  let tmp = '';
  land_purposes.demand_purpose.value.forEach((item) => {
    if (item.value === id) {
      tmp =  item.label;
    }
  })
  return tmp;
}

const formDialogRef = ref();
const data = reactive({
    pageSize:10,
    currentPage:1,
    totalPage:1,
    tableData: [
        
    ]
})

const pageChange = (page) =>{
  console.log(1)
  setTimeout(function (){
    loadLand(page);
  },1000)

}


const loadLand = (page) =>{
    CommonService.getMatchList({current:page,auditStatus:2}).then((res)=>{
        if(res.code === 0 && res.data){
          console.log(res.data.records)
            data.tableData = res.data.records;
            data.totalPage = res.data.pages;
            data.currentPage = res.data.current;
        }
    })
}
const   toDetail=(landInfo)=>{
  if(landInfo.auctionInfo &&  landInfo.transactionWay === "listing"){
    router.push("/trade/gp/"+this.landInfo.auctionInfo.id);
  }
  else if( landInfo.transactionWay === "auction" && landInfo.auctionInfo){
    router.push("/land/auction/"+landInfo.id);
  }
  else{
    router.push("/land/detail/"+landInfo.id);
  }
}

onMounted(() => {

  loadLand(1);
})

</script>
